<template>
  <step-template :subStep="subStep">
    <!-- 
      Fork component
      This substep only use is to move to another substep
      based on the 'onContinue' and 'onBack' props JSON 
    -->
  </step-template>
</template>

<script>
/**
 * To use:
 - in the database, go to table substeps
 - find the substep row that you want this component
 - update the row field component='step-fork'
 - update the row field component_props as a JSON like:
{
  "onContinue": {
    "variable": "clinicalCaseScenario",
    "action": "changeSubStep",
    "actionValue": {
      "subStepId": "clinicalCaseScenarioValue",
      "1": "70",
      "2": "71",
      "VALUE": "STEP"
    }
  },
  "onBack": {
    "variable": "subStepId",
    "action": "changeSubStep",
    "actionValue": {
      "77": "92",
    }
  }
}
OR
{
  "onContinue": {
    "variable": "clinicalCaseScenario",
    "action": "changeSubStep",
    "actionValue": {
      "1": "70",
      "2": "71",
      "VALUE": "STEP"
    }
  },
  "onBack": { "action": "changeSubStep", "actionValue": { "goto": subStepId } },
}

  */
import { mapState, mapActions, mapMutations } from "vuex";
import StepTemplate from "/src/components/steps/StepTemplate.vue";

export default {
  name: "StepFork",
  components: {
    StepTemplate,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    subStep: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    onContinue: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    onBack: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      caseData: (state) => state.caseData,
      lastNavigationWas: (state) => state.lastNavigationWas,
      lastSubStepIdWas: (state) => state.lastSubStepIdWas,
    }),
  },
  created() {
    if (this.lastNavigationWas === "continue") {
      this.onContinueCall();
    } else if (this.lastNavigationWas === "back") {
      this.onBackCall();
    }
  },
  methods: {
    ...mapMutations(["setForceNextSubStepTo"]),
    ...mapActions(["trackingAction"]),
    onContinueCall() {
      const action = this.onContinue.action;
      if (action === "changeSubStep") {
        if (this.onContinue.actionValue.goto) {
          this.moveToSubStep(parseInt(this.onContinue.actionValue.goto), "Continue");
        } else {
          const variableValue = this.getVariableValue(this.onContinue.variable);
          this.callChangeSubStep(this.onContinue.actionValue, variableValue, "Continue");
        }
      }
    },
    onBackCall() {
      const action = this.onBack.action;
      if (action === "changeSubStep") {
        if (this.onBack.actionValue.goto) {
          this.moveToSubStep(parseInt(this.onBack.actionValue.goto), "Back");
        } else {
          const variableValue = this.getVariableValue(this.onBack.variable);
          this.callChangeSubStep(this.onBack.actionValue, variableValue, "Back");
        }
      }
    },
    getVariableValue(variableName) {
      if (variableName === "lastSubStepIdWas") {
        return this.lastSubStepIdWas;
      }
      return this.caseData[variableName];
    },
    callChangeSubStep(obj, variableValue, tag) {
      const subStepId = obj[variableValue];
      // const subStepId = Object.keys(obj).find(
      //   (key) => obj[key] == variableValue
      // );
      this.moveToSubStep(parseInt(subStepId), tag);
    },
    moveToSubStep(subStepId, tag) {
      this.setForceNextSubStepTo(parseInt(subStepId));
      this.$root.$refs.AvatarPanel.nextSubStep();
      this.trackingAction("Fork " + tag + " to SubStep: " + subStepId);
    },
  },
};
</script>
