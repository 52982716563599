<template>
  <v-col
    cols="12"
    md="4"
    align-self="center"
    class="justify-center"
    style="z-index:0"
  >
    <v-card class="flex-card" flat transparent height="100%">
      <v-card-text>
        <v-container fluid>
          <v-row>  
            <v-col class="text-center">
              <h6 class="text-gray-500 font-italic">Please choose a patient avatar<br>or use the Randomizer.</h6>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "AvatarPanelBlank",
  components: {},
  data() {return {}},
  computed: {},
  methods: {},
};
</script>
<style>
</style>