<template>
  <v-row v-if="introduction">
    <v-col
      cols="12"
      md="4"
    >
      <v-card outlined class="flex-card" height="100%">
        <v-card-text>
          <v-img
            max-height="280"
            max-width="280"
            src="/images/GLOBAL/LOGO/BYOCS-logo-world.png"
            class="m-auto"
          ></v-img>
          <h6 class="pt-5 pb-5 font-weight-medium" v-if="subStep.description">
            <b>Welcome to:<br />
            {{ subStep.name }}</b>
          </h6>
          <h6>Here's what you'll do:</h6>
          <p class="pb-4 font-weight-medium">
            <span
              >1. Treat up to 3 patients<br />2. Spend some time in the
              Library<br />3. Collect some credit!</span
            >
          </p>
        </v-card-text>
        <v-card-actions class="justify-center pb-5 px-10">
          <v-btn
            class="white--text caption font-weight-bold"
            large
            rounded
            block
            color="orange"
            @click="avatarArea()"
          >
            Start treating patients
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="8"
    >
      <player :video="{id: video_id}" />

      <h5 class="pt-8 pb-6 text-center text-break text-gray-750 font-italic">
        When you complete the video, click Start Treating Patients.
      </h5>
      <p class="text-center font-weight-bold" style="font-size:1.4em;">
        Best experienced on
        <br />
        <v-icon large color="orange">
          mdi-google-chrome
        </v-icon>
        Google Chrome
      </p>
    </v-col>
  </v-row>

  <v-row v-else>
    <v-col
      cols="12"
      md="12"
    >
      <v-card flat height="100%">
        <v-card-text
          style="padding:0;"
        >
          <v-card
            color="transparent"
            style="position:relative;"
          >
            <img style="z-index:999;position:absolute;top:-10%;left:-6%;max-height:50%;max-width:50%" src="/images/GLOBAL/LOGO/BYOCS-logo-world.png" />
            <v-img
              cover
              :aspect-ratio="16/9"
              src="/images/GLOBAL/MAP/worldmap.png"
            ></v-img>
            <v-card v-for="(avatar, index) in avatars" :key="index"
              :style="{'position':'absolute', top: avatar.mapTop + '%', left: avatar.mapLeft + '%','animation-delay': index*200+300 + 'ms'}"
              color="transparent"
              flat
              class="avatar-card"
              rounded="0"
            >
              <img 
                @click="selectAvatar(avatar.id, false)"
                :src=avatar.iconUrl
                class="avatar"
              >
            </v-card>
            <v-card-actions
              style="justify-content: center"
            >
              <div
                class="pr-3"
              >
                Click a patient avatar on the map or
              </div>
            <v-btn
              class="white--text caption font-weight-bold"
              elevation="2"
              rounded
              color="#ff3f3f"
              @click="randomAvatar()"
            >
              <v-icon right>
                mdi-atom-variant
              </v-icon>
              Use the Randomizer
            </v-btn>
          </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import Player from "/src/components/player/Player.vue";

export default {
  name: "StepAvatarMap",
  components: {
    Player,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    subStep: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    variables: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    avatars: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    video_id: {
      type: [String],
      required: false,
      default: "000000",
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      avatar: (state) => state.avatar,
      introduction: (state) => state.introduction,
      case: (state) => state.case,
      caseData: (state) => state.caseData,
      currentStep: (state) => state.currentStep,
      currentSubStep: (state) => state.currentSubStep,
      continueButtonEnabled: (state) => state.continueButtonEnabled,
      avatarVariables() {
        if (this.avatar.variables) {
          return JSON.parse(this.avatar.variables);
        }
        return null;
      },
    }),
    avatarList() {
      return this.avatars;
    },
  },
  created() {
    this.setIntroduction(true);
    this.clearAvatar();
  },
  methods: {
    ...mapMutations([
        "setAvatar",
        "setIntroduction",
        "setContinueButtonEnabled"
      ]),
    ...mapActions(["trackingAction", "setCaseData"]),
    clearAvatar() {
      this.setAvatar({});
    },
    avatarArea() {
      this.setIntroduction(false);
    },
    selectAvatar(avatarSelected, random = false) {
      let avatar = {}
      const fullAvatar = this.filterAvatarById(avatarSelected)
      avatar = Object.fromEntries(Object.entries(fullAvatar).filter(([_, v]) => v != null));
      avatar.name = avatar.firstname + ' ' + avatar.lastName
      avatar.random = random
      this.setContinueButtonEnabled(true);
      this.setAvatar(avatar);
      if (avatar.random) {
        this.trackingAction("Avatar Selection: " + this.avatar.id + ": Randomizer");
      } else {
        this.trackingAction("Avatar Selection: " + this.avatar.id + ": Manual");
      }
      this.clearCurrentCaseData();
    },
    clearCurrentCaseData() {
      this.setCaseData({});
    },
    filterAvatarById(id) {
      return this.avatars.find((avatar) => {
        if(avatar.id === id) return true
      })
    },
    randomAvatar() {
      let avatarsIds = this.avatars.map(avatar => {
        return avatar.id
      })
      const random = avatarsIds[Math.floor(Math.random() * avatarsIds.length)];
      this.selectAvatar(random, true);
      //this.trackingAction("Randomizer Button Pressed");
    },
  },
};
</script>
<style>
  .randomizer-button {
    background: radial-gradient(at right center, rgb(203 0 0), rgb(119 13 21));
  }
  .avatar-card {
    animation-duration: 1s;
    animation-name: animate-fade;
    animation-fill-mode: backwards;
    background: rgba(0,0,0,.5);
  }
  @keyframes animate-fade {
    0% { opacity: 0; transform: scale(1.5) }
    100% { opacity: 1; transform: scale(1) }
  }
  .avatar {
    width:56px;
    height:56px;
    position: relative;
    border-radius: 50% !important;
    border: 2px solid gold;
    box-shadow: 2px 10px 20px rgba(0,0,0,0.3);
    z-index: 1;
    transition: all .15s ease-in-out;
    transform: translate(100%, 100%);
    
  }
  .avatar:hover {
    width: 112px;
    height:112px;
    transform: translate(28px, 28px);
    z-index: 999 !important;
  }
  @media screen and (min-width: 501px) {
    .avatar {
      /* animation: avatar-revert .25s; */
    }
    .avatar:hover {
      /* animation-name: pulse; */
    }
  }
  @media screen and (max-width: 500px) {
    .avatar {
      width: 40px;
      height: 40px;
      transform: translate(60%,60%);
    }
    .avatar:hover {
      width: 80px;
      height: 80px;
    }
  }

  @keyframes pulse {
    1%   {z-index: 999;}
    25%  {transform: scale(0.9);}
    75%  {transform: scale(2.7);}
    100% {transform: scale(2.5);}
  }
  @keyframes pulse-small {
    1%   {z-index: 999;}
    25%  {transform: scale(0.6);}
    75%  {transform: scale(2.7);}
    100% {transform: scale(2.5);}
  }
  @keyframes avatar-revert {
    0%   {transform: scale(2.5);}
    50%  {transform: scale(1);}
    75%  {transform: scale(.8);}
    100% {transform: scale(1);}
  }
  @keyframes avatar-revert-small {
    0%   {transform: scale(2.5);}
    50%  {transform: scale(.7);}
    75%  {transform: scale(.6);}
    100% {transform: scale(.7);}
  }
</style>