<template>
  <v-card class="flex-card" height="100%">
    <v-card-title v-if="subStep.description">
      {{ subStep.description.toUpperCase() }}
    </v-card-title>
    <v-divider></v-divider>
    <slot> </slot>
  </v-card>
</template>

<script>
export default {
  name: "StepTemplate",
  props: {
    subStep: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
};
</script>
