<template>
  <step-template :subStep="subStep">
    <div class="medical-history">
      <v-row class="px-6">
        <v-col
          cols="12"
          md="6"
          class="pb-0"
        >
          <v-card class="mb-4" color="#f0f4f4">
            <v-card-title>
              <v-icon color="teal" class="pr-4">
                mdi-comment
              </v-icon>
              Patient Comment
            </v-card-title>
            <v-card-text v-html="patientComments" class="font-italic"></v-card-text>
          </v-card>          <v-card class="mb-4" color="#f0f4f4">
            <v-card-title>
              <v-icon color="teal" class="pr-4">
                mdi-format-list-text
              </v-icon>
              Medical History
            </v-card-title>
            <v-card-text v-html="medicalHistory"></v-card-text>
          </v-card>
          <v-card class="mb-4" color="#f0f4f4">
            <v-card-title>
              <v-icon color="teal" class="pr-4">
                mdi-medical-bag
              </v-icon>
              Treatment Regimen
            </v-card-title>
            <v-card-text v-html="treatmentRegimen"></v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="pb-0"
        >
          <v-card class="mb-4" color="#f0f4f4">
            <v-card-title>
              <v-icon color="teal" class="pr-4">
                mdi-test-tube
              </v-icon>
              Results
            </v-card-title>
            <v-card-text v-html="labResults"></v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </step-template>
</template>

<script>
import { mapState, mapActions } from "vuex";
import StepTemplate from "/src/components/steps/StepTemplate.vue";

export default {
  name: "StepProfileV2",
  components: {
    StepTemplate,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    subStep: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    avatars: {
      type: Array,
      required: true,
      default: () => {
        return {};
      },
    },
    avatarsCombinations: {
      type: Array,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      medicalHistory: "loading...",
      patientComments: "loading...",
      treatmentRegimen: "loading...",
      labResults: "loading...",
    };
  },
  computed: {
    ...mapState({
      avatar: (state) => state.avatar,
      caseData: (state) => state.caseData,
    }),
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions(["setCaseDataValue"]),
    init() {
      const avaCombinations = this.filterAvatarsCombinationsByAvatarId();
      const avaCombination = this.filterAvatarsCombinationsByVariables(
        avaCombinations
      );
      this.loadMedicalHistory(avaCombination);
      this.loadPatientComments(avaCombination);
      this.loadTreatmentRegimen(avaCombination);
      this.loadLabResults(avaCombination);
    },
    filterAvatarsCombinationsByAvatarId() {
      return this.avatarsCombinations.filter((avatarCombination) => {
        if (avatarCombination.id_avatar === this.avatar.id) return true;
        return false;
      });
    },
    filterAvatarsCombinationsByVariables(myAvatarCombinations) {
      return myAvatarCombinations.find((avatarCombination) => {
        let match = false;
        for (const key in avatarCombination.variables) {
          if (avatarCombination.variables[key] === this.caseData[key]) {
            match = true;
          } else {
            return false;
          }
        }
        return match;
      });
    },
    loadMedicalHistory(avaCombination) {
      this.medicalHistory = avaCombination.medicalHistory;
      this.setCaseDataValue({
        key: "medicalHistory",
        value: this.medicalHistory,
        trackAction: false,
      });
    },
    loadPatientComments(avaCombination) {
      this.patientComments = '&ldquo;' + avaCombination.patientComments  + '&rdquo;';
      this.setCaseDataValue({
        key: "patientComments",
        value: this.patientComments,
        trackAction: false,
      });
    },
    loadTreatmentRegimen(avaCombination) {
      this.treatmentRegimen = avaCombination.regimen;
      this.setCaseDataValue({
        key: "treatmentRegimen",
        value: this.treatmentRegimen,
        trackAction: false,
      });
    },
    loadLabResults(avaCombination) {
      const caseDataLabKeys = ["a1c", "bmi"];
      let caseDataLabValues = "";
      for (const i in caseDataLabKeys) {
        const key = caseDataLabKeys[i];
        if (this.caseData[key]) {
          caseDataLabValues += `${key.toUpperCase()} level - ${
            this.caseData[key]
          }<br>`;
        }
      }

      this.labResults = caseDataLabValues + avaCombination.labResults;

      this.setCaseDataValue({
        key: "labResults",
        value: this.labResults,
        trackAction: false,
      });
    },
  },
};
</script>
<style>
.medical-history .theme--light.v-card > .v-card__text,
.medical-history .theme--light.v-card > .v-card__subtitle {
    color: #404040;
    font-weight: bold;
    font-size: 1.1em;
    line-height: 1.6em;
}
</style>