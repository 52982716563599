<template>
  <step-template :subStep="subStep">
    <div class="bmi-waist-risk">
      <v-row class="px-6">
        <v-col
          cols="12"
          class="pb-0"
        >
          <div
            class="info-wrapper"
          >
            <h4 class="pt-8 text-center">{{ this.avatar.name }}'s BMI is {{ this.caseData.ccs3_bmi }} and Waist Circumference is {{ this.caseData.ccs3_waist }}cm.<br /><br />
            Given this profile, the disease risk is:
            </h4>
            <h2 class="pt-5 text-center">
              <u>{{ diseaseRisk.toUpperCase() }}</u>
            </h2>
          
            <img
                  src="/images/BYOCS3/OTHER/BYOCS3_OTHER_BMI-Graphic-Alt.png"
                  class="bmi-image"
            >
            <h6 class="text-center">
              Please click the continue button.
            </h6>
          </div>
        </v-col>
      </v-row>
    </div>
  </step-template>
</template>

<script>
import { mapState, mapActions } from "vuex";
import StepTemplate from "/src/components/steps/StepTemplate.vue";

export default {
  name: "StepBmiWaist",
  components: {
    StepTemplate,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    subStep: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    avatars: {
      type: Array,
      required: true,
      default: () => {
        return {};
      },
    }
  },
  data() {
    return {
      diseaseRisk: "loading...",
    };
  },
  computed: {
    ...mapState({
      avatar: (state) => state.avatar,
      caseData: (state) => state.caseData,
    }),
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions(["setCaseDataValue"]),
    init() {
      this.processDiseaseRisk();
    },
    processDiseaseRisk() {
      let bmiRisk = this.caseData.ccs3_bmiRisk;
      let wcRisk = this.caseData.ccs3_circumferenceRisk;
      if (wcRisk === "above") {
        if (bmiRisk === "overweight") {
          this.diseaseRisk = "high";
        } else if (bmiRisk === "obesity_1" || bmiRisk === "obesity_2") {
          this.diseaseRisk = "very high";
        } else if (bmiRisk === "obesity_3") {
          this.diseaseRisk = "extremely high";
        }
      } else if (wcRisk === "below") {
        if (bmiRisk === "overweight") {
          this.diseaseRisk = "increased";
        } else if (bmiRisk === "obesity_1") {
          this.diseaseRisk = "high";
        } else if (bmiRisk === "obesity_2") {
          this.diseaseRisk = "very high";
        } else if (bmiRisk === "obesity_3") { 
          this.diseaseRisk = "extremely high";
        }
      } else {
        this.diseaseRisk = "unable to determine";
      }
      this.setCaseDataValue({ key: "ccs3_diseaseRisk", value: this.diseaseRisk });
    },
  },
};
</script>
<style>
  .info-wrapper {
    max-width: 550px;
    margin: 0 auto;
  }
  .bmi-image {
    opacity: .5;
    display:block;
    width:100%;
    height:auto;
    max-width: 450px;
    margin: 60px auto 40px;
  }
</style>