<template>
  <v-btn
      :href=buttonUrl
      target="_blank"
      class="white--text caption font-weight-bold mb-2 mr-2"
      small
      elevation="2"
      rounded
      color="blue"
      @click="trackResource(buttonUrl)"
    >
    <v-icon
      small
      class="mr-1"
    >
        mdi-page-next-outline
    </v-icon>
    {{ buttonName }}
  </v-btn>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "AvatarResources",
  props: {
    buttonName: {
      type: String,
      required: true,
    },
    buttonUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      libraryOpen: (state) => state.showLibrary,
    }),
  },
  watch: {
  },
  methods: {
    ...mapActions(["trackingAction"]),
    trackResource(url) {

      if (this.libraryOpen) {
        // Watched in Library
        this.trackingAction("Clicked Resource in Library: " + url);
      } else {
        // Watched in Step
        this.trackingAction("Clicked Resource: " + url);
      }
  }  
  },
};
</script>
<style>

</style>
