<template>
  <div>
    <div
      class="wistia_responsive_padding"
      style="padding:56.25% 0 0 0;position:relative;"
    >
      <div
        class="wistia_responsive_wrapper"
        style="height:100%;left:0;position:absolute;top:0;width:100%;"
      >
        <div
          :class="`wistia_embed wistia_async_${_video.id}`"
          style="width:100%;height:100%;"
        >
          <div v-if="!videoPlayer" class="pt-5 pb-4 text-center">
            <Loading
              class="d-flex align-center mx-5"
              :style="`${cLoadingHeight}`"
            />
          </div>
        </div>
      </div>
    </div>
    <v-snackbar v-model="alert.show" :timeout="4000" color="red" text>
      {{ alert.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="alert.show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script
  charset="ISO-8859-1"
  src="//fast.wistia.com/assets/external/E-v1.js"
  async
></script>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Loading from "/src/components/base/Loading.vue";
export default {
  name: "Player",
  components: {
    Loading,
  },
  props: {
    user: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    video: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    startTime: {
      type: Number,
      required: false,
      default: 0,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      _video: {},
      videoPlayer: null,
      alert: {
        show: false,
        text: "",
      },
    };
  },
  computed: {
    ...mapState({
      libraryOpen: (state) => state.showLibrary,
      showCaptions: (state) => state.showCaptions,
    }),
    cLoadingHeight() {
      if (!this.isMobile) return "min-height: 400px;";
      else return "min-height: 100px;";
    },
  },
  watch: {
    video(val) {
      this.setVideo(val);
    },
    startTime(val) {
      this.setStartTime(val);
    },
  },
  created() {
    this.setUpEventListeners();
  },
  async beforeMount() {
    this.setVideo(this.video);
    this.initPlayer();
  },
  beforeUpdate() {
    this.$emit("onStateChange", this.getEmitVideoData("beforeUpdate"));
    this.setUpEventListeners();
  },
  beforeDestroy() {
    this.$emit("onStateChange", this.getEmitVideoData("beforeDestroy"));
    this.removeEventListeners();
    this.closePlayer();
  },
  methods: {
    ...mapActions(["trackingAction"]),
    ...mapMutations(["setShowCaptions"]),
    setUpEventListeners() {
      window.addEventListener("beforeunload", this.beforeWindowUnload);
    },
    removeEventListeners() {
      window.removeEventListener("beforeunload", this.beforeWindowUnload);
    },
    beforeWindowUnload(e) {
      if (this.isVideoPlaying()) {
        this.$emit("onStateChange", this.getEmitVideoData("beforeDestroy"));
        e.preventDefault();
        e.returnValue = "";
      }
    },
    isVideoPlaying() {
      if (this.videoPlayer && this.videoPlayer.state() === "playing") {
        return true;
      }
      return false;
    },
    setVideo(video) {
      this._video = video;
    },
    getPlayerOptions() {
      const self = this;
      return {
        id: this._video.id,
        options: {
          plugin: {
            "captions-v1": {
              onByDefault: this.showCaptions,
              language: 'eng',
              subtitlesScale: 1
            }
          }
        },
        onReady: function(video) {
          self.videoPlayer = video;

          self.videoPlayer.bind("play", () => {
            self.$emit("onPlay", self.getEmitVideoData("play"));
            self.trackVideo("Played");
          });

          self.videoPlayer.bind("captionschange", (details) => {
            if (details.visible) {
              self.$emit("captionschange", self.getEmitVideoData("CaptionsOn"));
              self.trackVideo("CaptionsOn");
              self.setShowCaptions(true);
            } else {
              self.$emit("captionschange", self.getEmitVideoData("CaptionsOff"));
              self.trackVideo("CaptionsOff");
              self.setShowCaptions(false);
            }
          });


          self.videoPlayer.bind("pause", () => {
            self.$emit("onPause", self.getEmitVideoData("pause"));
            self.trackVideo("Paused");
          });

          self.videoPlayer.bind("timechange", function(t) {
            self.$emit(
              "onPercentChanged",
              self.getEmitVideoData("percentChanged")
            );
          });

          self.videoPlayer.bind("seek", function(currentTime, lastTime) {
            self.$emit(
              "onSeek",
              self.getEmitVideoData("seek")
            );
            // subtract .3 for the lagging 300ms since last calling
            //self.trackVideo("Seeked", parseInt(currentTime - lastTime - .3));
            self.trackVideo("Seeked");
          });

          self.videoPlayer.bind("end", function(t) {
            self.$emit(
              "onEnd",
              self.getEmitVideoData("end")
            );
            self.trackVideo("Completed");
          });

          if (self.startTime) {
            self.setStartTime(self.startTime);
          }

          self.$emit("onStateChange", self.getEmitVideoData("playerReady"));
        },
      };
    },
    setStartTime(seconds) {
      if (this.videoPlayer) {
        this.videoPlayer.time(seconds);
      }
    },
    setCaptions() {
      
      return true;      

    },
    trackVideo(action, seconds = false, data = "") {

        var videoSecTag = ""
        /*
        if (this.getVideoWatchedSeconds() == 0) {
          videoSecTag = "at Start";
        } else if (action == "Seeked") {
          // videoSecTag = `by ${seconds} seconds`;
          videoSecTag = `to ${this.getVideoWatchedSeconds()} seconds of ${this.getVideoTotalSeconds()}`;
        } else {
          videoSecTag = `at ${this.getVideoWatchedSeconds()} seconds of ${this.getVideoTotalSeconds()}`;
        }
        */
        if (this.getVideoWatchedSeconds() == 0) {
          videoSecTag = "at Start";
        } else {
          videoSecTag = `at ${this.getVideoWatchedSeconds()} seconds`;
        }

        if (this._video.id) {
          if (action == 'Paused' && this.getVideoWatchedSeconds() == 0) {
            // don't track when paused at 0
          } else if (this.libraryOpen) {
            // Watched in Library
            this.trackingAction(
            `${action} Video in Library: ${
              this._video.id
            }: ${videoSecTag}`
            );
          } else {
            // Watched outside Library
            this.trackingAction(
            `${action} Video: ${
              this._video.id
            }: ${videoSecTag}`
            );
          }
        }
    },
    initPlayer() {
      if (!this._video || !this._video.id) {
        this.showAlert("Failed to load video. No id received.");
        return;
      }
      const options = this.getPlayerOptions();
      window._wq = window._wq || [];
      _wq.push(options);
    },
    closePlayer() {
      const options = this.getPlayerOptions();
      window._wq.push({ revoke: options });
    },
    getVideoWatchedPercent() {
      return parseInt(
        (this.videoPlayer.time() * 100) / this.videoPlayer.duration()
      );
    },
    getVideoWatchedSeconds() {
      return parseInt(this.videoPlayer.time());
    },
    getVideoTotalSeconds() {
      return parseInt(this.videoPlayer.duration());
    },
    getEmitVideoData(event) {
      if (!this.videoPlayer) {
        return;
      }
      const emitVideoData = {
        event,
        videoId: this.video.id,
        secondsWatched: this.getVideoWatchedSeconds(),
        percentWatched: this.getVideoWatchedPercent(),
        duration: this.getVideoTotalSeconds(),
      };
      return emitVideoData;
    },
    showAlert(text) {
      this.alert.text = text;
      this.alert.show = true;
    },
  },
};
</script>

<style scoped></style>
